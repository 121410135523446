<template>
  <div id="app">
    <div class="d-flex justify-content-start">
      <div class="app-body w-100">
        <Router />
      </div>
    </div>
    <sign-up-modal />
  </div>
</template>

<script>
  import Router from '@/components/Router.vue';
  import { isTokenExpired } from '@/common/auth';
  import { mapActions } from 'vuex';
  import ClickTrackingService from '@/services/ClickTrackingService';
  import DashboardService from '@/services/DashboardService';
  import SignUpModal from '@/components/signup/SignUpModal.vue';
  import { v4 as uuidv4 } from 'uuid';

  export default {
    name: 'App',
    metaInfo() {
      return {
        title: 'ModelFusion',
        titleTemplate: 'ModelFusion | %s',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'Simultaneously chat with leading 23+ LLM models using MultiChat using ModelFusion.',
          },
        ],
      };
    },
    components: {
      SignUpModal,
      Router,
    },
    data() {
      return {
        tokenCheckInterval: null,
        visitStartTime: null,
        pageViewStartTime: null,
      };
    },
    methods: {
      ...mapActions(['signOut']),
      checkToken() {
        const token = JSON.parse(localStorage.getItem('user'))?.jwtToken;
        if (token && isTokenExpired(token)) {
          this.signOut();
          if (this.$route.path !== '/logout') {
            this.$router.push({ path: '/logout', query: this.$route.query });
          }
        }
      },
      setupTracking() {
        // Set up click tracking
        this.setupClickTracking();

        this.$router.afterEach((to) => {
          // Reset page view start time
          this.pageViewStartTime = new Date();
          // Send data for the new page
          this.sendDashboardEvent(this.createDashboardEvent('pageview', to.path));
        });

        window.addEventListener('beforeunload', this.handleBeforeUnload);
      },
      setupClickTracking() {
        document.addEventListener('click', this.handleClick);
      },
      handleClick(event) {
        if (this.$store.getters.isImpersonating) {
          return;
        }
        const clickEvent = {
          userId: this.$store.state.user?.guid || null,
          sessionId: this.getSessionId(),
          elementClicked: event.target.tagName,
          pageUrl: window.location.href,
          timestamp: new Date().toISOString(),
          additionalData: JSON.stringify({
            className: event.target.className,
            id: event.target.id,
            innerText: event.target.innerText,
          }),
          deviceInfo: this.getDeviceInfo(),
        };
        ClickTrackingService.trackClick(clickEvent);
      },

      createDashboardEvent(eventType, path) {
        const currentTime = new Date();
        const visitDuration = this.visitStartTime ? currentTime - this.visitStartTime : 0;
        const pageViewDuration = this.pageViewStartTime ? currentTime - this.pageViewStartTime : 0;

        return {
          id: uuidv4(),
          timestamp: currentTime.toISOString(),
          userId: this.$store.state.user?.guid || null,
          sessionId: this.getSessionId(),
          eventType: eventType,
          pageUrl: path || window.location.pathname,
          referrer: document.referrer || 'direct/unknown',
          visitDuration: visitDuration,
          pageViewDuration: pageViewDuration,
          isBounce: this.isFirstPageView(),
          deviceType: this.getDeviceType(),
          browser: this.getBrowserInfo(),
          operatingSystem: this.getOSInfo(),
        };
      },
      async sendDashboardEvent(event) {
        try {
          console.log(event);
          await DashboardService.sendEvent(event);
        } catch (error) {
          console.error('Error sending dashboard event:', error);
        }
      },
      getDeviceInfo() {
        return {
          screenResolution: `${window.screen.width}x${window.screen.height}`,
          deviceType: this.getDeviceType(),
          browser: this.getBrowserInfo(),
          operatingSystem: this.getOSInfo(),
        };
      },
      getDeviceType() {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          return 'tablet';
        }
        if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
          return 'mobile';
        }
        return 'desktop';
      },
      getBrowserInfo() {
        const ua = navigator.userAgent;
        let browserName = 'Unknown';
        let browserVersion = 'Unknown';

        if (ua.indexOf('Firefox') > -1) {
          browserName = 'Firefox';
          browserVersion = ua.match(/Firefox\/(\d+)/)[1];
        } else if (ua.indexOf('Chrome') > -1) {
          browserName = 'Chrome';
          browserVersion = ua.match(/Chrome\/(\d+)/)[1];
        } else if (ua.indexOf('Safari') > -1) {
          browserName = 'Safari';
          browserVersion = ua.match(/Version\/(\d+)/)[1];
        } else if (ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1) {
          browserName = 'Internet Explorer';
          browserVersion = ua.match(/(?:MSIE |rv:)(\d+)/)[1];
        }

        return `${browserName} ${browserVersion}`;
      },
      getOSInfo() {
        const ua = navigator.userAgent;
        let os = 'Unknown';

        if (ua.indexOf('Win') > -1) os = 'Windows';
        if (ua.indexOf('Mac') > -1) os = 'MacOS';
        if (ua.indexOf('Linux') > -1) os = 'Linux';
        if (ua.indexOf('Android') > -1) os = 'Android';
        if (ua.indexOf('like Mac') > -1) os = 'iOS';

        return os;
      },
      getSessionId() {
        let sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId) {
          sessionId = uuidv4();
          sessionStorage.setItem('sessionId', sessionId);
        }
        return sessionId;
      },
      isFirstPageView() {
        return !sessionStorage.getItem('pageViewed');
      },
      handleBeforeUnload() {
        const event = this.createDashboardEvent('pageview', window.location.pathname);
        console.log('DASHBAOARD');
        console.log(event);
        //navigator.sendBeacon('/api/dashboard', JSON.stringify(event));
      },
    },
    mounted() {
      this.tokenCheckInterval = setInterval(this.checkToken, 60000);
      this.checkToken();
      this.setupTracking();
      this.visitStartTime = new Date();
      this.pageViewStartTime = new Date();
      sessionStorage.setItem('pageViewed', 'true');
    },
    beforeDestroy() {
      if (this.tokenCheckInterval) {
        clearInterval(this.tokenCheckInterval);
      }
      document.removeEventListener('click', this.handleClick);
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
  };
</script>
