// src/constants/planConstants.js

export const PLANS = [
    {
      name: 'Basic',
      headerBgVariant: 'light',
      headerTextVariant: 'dark',
      weeklyFeatures: [
        'Premium AI Models: GPT-4, Claude 3 Haiku and Sonnet, Amazon Titan, Cohere Command, Mistral AI, and more!',
        '25,000 Fusion Credits *',
      ],
      monthlyFeatures: [
        'Premium AI Models: GPT-4, Claude 3 Haiku and Sonnet, Amazon Titan, Cohere Command, Mistral AI, and more!',
        '115,000 Fusion Credits *',
      ],
      originalPriceMonthly: '$20/mo',
      salePriceMonthly: '$10/mo',
      originalPriceWeekly: '$6/week',
      salePriceWeekly: '$3/week',
      weeklyLink: 'https://buy.stripe.com/7sI6sq7uc7ap57q28f?prefilled_promo_code=PRODUCTHUNT50',
      link: 'https://buy.stripe.com/eVa2cadSA66larKaEH?prefilled_promo_code=PRODUCTHUNT50',
    },
    {
      name: 'Plus',
      headerBgVariant: 'secondary',
      headerTextVariant: 'white',
      popular: true,
      weeklyFeatures: [
        'Superior AI Models: GPT-4o, Claude 3.5, Claude 3 Opus, Meta Llama 3, and more!',
        'Standard AI Models: GPT-4 Mini, Claude 3 Haiku and Sonnet, Amazon Titan, Cohere Command, Mistral AI, and more!',
        '50,000 Fusion Credits *',
      ],
      monthlyFeatures: [
        'Superior AI Models: GPT-4o, Claude 3.5, Claude 3 Opus, Meta Llama 3, and more!',
        'Standard AI Models: GPT-4 Mini, Claude 3 Haiku and Sonnet, Amazon Titan, Cohere Command, Mistral AI, and more!',
        '200,000 Fusion Credits *',
      ],
      originalPriceMonthly: '$30/mo',
      salePriceMonthly: '$15/mo',
      originalPriceWeekly: '$8/week',
      salePriceWeekly: '$4/week',
      weeklyLink: 'https://buy.stripe.com/8wM5om15OdyN7fy14a?prefilled_promo_code=PRODUCTHUNT50',
      link: 'https://buy.stripe.com/aEU8Ay6q8gKZbvO8wA?prefilled_promo_code=PRODUCTHUNT50',
    },
    {
      name: 'Pro',
      headerBgVariant: 'light',
      headerTextVariant: 'dark',
      weeklyFeatures: [
        'Superior AI Models: GPT-4o, Claude 3.5, Claude 3 Opus, Meta Llama 3, and more!',
        'Standard AI Models: GPT-4 Mini, Claude 3 Haiku and Sonnet, Amazon Titan, Cohere Command, Mistral AI, and more!',
        '75,000 Fusion Credits *',
      ],
      monthlyFeatures: [
        'Superior AI Models: GPT-4o, Claude 3.5, Claude 3 Opus, Meta Llama 3, and more!',
        'Standard AI Models: GPT-4 Mini, Claude 3 Haiku and Sonnet, Amazon Titan, Cohere Command, Mistral AI, and more!',
        '350,000 Fusion Credits *',
      ],
      originalPriceMonthly: '$50/mo',
      salePriceMonthly: '$25/mo',
      originalPriceWeekly: '$14/week',
      salePriceWeekly: '$7/week',
      weeklyLink: 'https://buy.stripe.com/00gbMK7ucfGV6bueV2?prefilled_promo_code=PRODUCTHUNT50',
      link: 'https://buy.stripe.com/eVa3ge4i0bqFczSfZ3?prefilled_promo_code=PRODUCTHUNT50',
    },
  ];
  