<template>
  <b-container fluid class="px-0">
    <KeepAlive :include="['HomePage', 'chat-page']">
      <router-view :key="$route.fullPath" />
    </KeepAlive>
  </b-container>
</template>

<script>
  export default {
    name: 'RouterComponent',
  };
</script>
