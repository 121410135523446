<template>
  <b-modal
    v-model="showModal"
    id="signup-modal"
    hide-footer
    hide-header
    :no-close-on-backdrop="nonDismissable"
    :no-close-on-esc="nonDismissable"
    :hide-header-close="nonDismissable"
  >
    <sign-up v-if="showSignUp" @close="closeModal" @login="goToLogin" modal />
    <login v-else-if="showLogin" @close="closeModal" @signup="goToSignUp" modal />
  </b-modal>
</template>

<script>
  import SignUp from '@/components/signup/SignUp.vue';
  import Login from '@/components/signup/Login.vue';

  export default {
    name: 'SignUpModal',
    components: {
      SignUp,
      Login,
    },
    data() {
      return {
        showModal: false,
        showSignUp: false,
        showLogin: false,
        showRefresh: false,
        nonDismissable: true,
        payload: {},
      };
    },
    created() {
      this.$root.$on('show-signup-modal', (payload) => this.showSignUpModal(payload));
      this.$root.$on('show-login-modal', (payload) => this.showLoginModal(payload));
    },
    beforeDestroy() {
      this.$root.$off('show-signup-modal', (payload) => this.showSignUpModal(payload));
      this.$root.$on('show-login-modal', (payload) => this.showLoginModal(payload));
    },
    methods: {
      goToSignUp() {
        this.showSignUp = true;
        this.showLogin = false;
      },
      goToLogin() {
        this.showSignUp = false;
        this.showLogin = true;
      },
      showLoginModal(payload) {
        this.showModal = true;
        this.showLogin = true;
        if (payload?.canDismiss === true) {
          this.nonDismissable = false;
        }
        this.payload = payload;
      },
      showSignUpModal(payload) {
        this.showModal = true;
        this.showSignUp = true;
        if (payload?.canDismiss === true) {
          this.nonDismissable = false;
        }
        this.payload = payload;
      },
      closeModal() {
        this.showModal = false;
        this.nonDismissable = true;
        this.showSignUp = false;
        this.showLogin = false;
        this.$root.$emit('close-signup-modal', this.payload);
      },
    },
  };
</script>
