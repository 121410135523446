// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import UserService from '../services/UserService'
// import SubscriptionsService from '../services/SubscriptionsService'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    isAuthenticated: false,
    isAdmin: false,
    jwtToken: null,
    isImpersonating: false
  },
  mutations: {
    clearUser(state) {
      state.user = null
      state.subscription = null
      state.isAuthenticated = false
      state.isAdmin = false
      state.jwtToken = null
    },
    setUser(state, user) {
      state.user = user
      state.isAuthenticated = true
      state.isAdmin = user.isAdmin
      state.jwtToken = user.jwtToken
      state.isImpersonating = user.isImpersonated || false
    },
  },
  actions: {
    async signIn({ commit }, payload) {
      try {
        const user = await UserService.signIn(payload.email, payload.password)
        commit('setUser', user)
        localStorage.setItem('user', JSON.stringify(user))
        return user
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async createAccount({ commit }, payload) {
      try {
        const user = await UserService.createAccount(payload)
        commit('setUser', user)
        localStorage.setItem('user', JSON.stringify(user))
        return user
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    signOut({ commit }) {
      commit('clearUser')
      localStorage.removeItem('user')
    },
    tryAutoSignIn({ commit }) {
      const user = localStorage.getItem('user')
      if (user) {
        commit('setUser', JSON.parse(user))
      }
    }
  },
  getters: {
    user: (state) => state.user,
    isAuthenticated: (state) => state.isAuthenticated,
    isAdmin: (state) => state.isAdmin,
    jwtToken: (state) => state.jwtToken,
    isImpersonating: (state) => state.isImpersonating
  }
})

export default store
