import BaseService from './BaseService';
import axios from 'axios';

class DashboardService extends BaseService {
  constructor() {
    super();
    this.apiUrl = `${this.baseUrl}/api/dashboard`;
  }

  async sendEvent(event) {
    try {
      // Convert visitDuration to a string in the format "HH:mm:ss"
      const visitDurationString = this.formatDuration(event.visitDuration);
      
      // Create the dashboardEvent object
      event["visitDuration"] = visitDurationString

      const response = await axios.post(this.apiUrl, event);
      return response.data;
    } catch (error) {
      console.error('Error sending dashboard event:', error);
      throw error;
    }
  }

  formatDuration(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
  }

  padZero(num) {
    return num.toString().padStart(2, '0');
  }

  async getDashboardSummary(startDate, endDate, jwtToken) {
    try {
      const response = await axios.get(`${this.apiUrl}/summary`, {
        params: { start: startDate, end: endDate },
        headers: {
            'Authorization': `Bearer ${jwtToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching dashboard summary:', error);
      throw error;
    }
  }
}

export default new DashboardService();
