<template>
  <div class="login-container">
    <div class="login-form">
      <div class="logo">
        <h1 class="py-2"><img src="@/assets/ModelFusionIcon.png" alt="ModelFusion" /> Login</h1>
      </div>
      <br />
      <b-alert v-if="loginError" variant="danger" show dismissible @dismissed="loginError = false" class="custom-alert">
        <template #dismiss>
          <span class="dismiss">&times;</span>
        </template>
        Invalid email or password.
      </b-alert>
      <b-alert v-if="passwordReset" variant="success" show dismissible @dismissed="passwordReset = false" class="custom-alert">
        Reset email sent.
        <template #dismiss>
          <span class="dismiss">&times;</span>
        </template>
      </b-alert>
      <form @submit.prevent="onSubmit">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="user.email" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="user.password" required />
        </div>
        <div class="remember-forgot">
          <!-- <div class="remember-me">
            <input type="checkbox" id="remember" v-model="user.rememberMe" />
            <label for="remember" class="ml-2">Remember me</label>
          </div> -->
          <a href="#" class="forgot-password" @click="resetPasswordRequest">Forgot password?</a>
        </div>
        <b-button type="submit" variant="primary" class="login-btn">Login</b-button>
      </form>
      <p class="signup-link align-items-center">Don't have an account? <b-button variant="link" @click="goToSignUp" class="mb-1">Sign up!</b-button></p>
    </div>
  </div>
</template>
<script>
  import UserService from '@/services/UserService';

  export default {
    name: 'LoginForm',
    data() {
      return {
        passwordReset: false,
        loginError: false,
        user: {
          email: '',
          password: '',
          rememberMe: false,
        },
      };
    },
    props: {
      modal: Boolean,
    },
    methods: {
      async onSubmit() {
        // Call API to authenticate user
        try {
          let response = await this.$store.dispatch('signIn', this.user);
          if (response && !this.modal && this.$route.path !== '/home') {
            this.$router.push({ path: '/home', query: this.$route.query });
          }
          if (this.modal) {
            this.emitClose();
          }
        } catch {
          this.loginError = true;
        }
      },
      async resetPasswordRequest() {
        let response = await UserService.requestPasswordReset(this.user.email);
        if (response) {
          this.passwordReset = true;
        }
      },
      goToSignUp() {
        if (this.modal) {
          this.$emit('signup');
        } else {
          this.emitSignup();
        }
      },
      emitClose() {
        this.$emit('close');
      },
      emitSignup() {
        if (this.$route.path !== '/signup') {
          this.$router.push({ path: '/signup', query: this.$route.query });
        }
      },
    },
  };
</script>
<style scoped>
  .login-form {
    min-width: 40%;
    max-width: 400px;
    padding: 40px;
    margin: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .dismiss {
    background: none;
    border: none;
  }

  .logo img {
    width: 50px;
    height: 50px;
  }
  .login-form {
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input[type='email'],
  input[type='password'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }

  input[type='email']:hover,
  input[type='password']:hover,
  input[type='email']:focus,
  input[type='password']:focus {
    border-color: #007bff;
  }

  .remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .remember-me {
    display: flex;
    align-items: center;
  }

  .forgot-password {
    color: #007bff;
    text-decoration: none;
  }

  .forgot-password:hover {
    text-decoration: underline;
  }

  .login-btn {
    width: 100%;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .custom-alert .close {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: inherit;
    text-shadow: none;
    opacity: 0.5;
  }

  .custom-alert .close:hover {
    opacity: 0.75;
  }

  .signup-link {
    margin-top: 20px;
    text-align: center;
  }

  .signup-link a {
    color: #007bff;
    text-decoration: none;
  }

  .signup-link a:hover {
    text-decoration: underline;
  }
</style>
