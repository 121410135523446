import BaseService from './BaseService'
import axios from 'axios';

class UserService extends BaseService {
  constructor() {
    super();
    this.apiUrl = `${this.baseUrl}/api/Users`;
  }

  async validateAccessKey(accessKey) {
    try {
      const response = await fetch(`${this.apiUrl}/access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ accessKey })
      });
      
      return await response.json();
    } catch (error) {
      console.error('Error validating access key:', error);
      return false;
    }
  }

  async getUserByGuid(guid, jwtToken) {
    try {
      const response = await axios.get(`${this.apiUrl}/user?guid=${guid}`, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`
        }
      });
      return response.data
    } catch (error) {
      console.error('Error fetching users:', error)
      throw error
    }
  }

  async getUsers(jwtToken) {
    try {
      const response = await fetch(this.apiUrl, {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      });
      return await response.json();
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  }

  async impersonateUser(userGuid, jwtToken) {
    try {
      const response = await axios.post(`${this.apiUrl}/impersonate`, { userGuid }, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error impersonating user:', error);
      throw error;
    }
  }
  

  async resetPassword(resetToken, newPassword) {
    try {
      const response = await fetch(`${this.apiUrl}/resetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ resetToken, newPassword })
      });

      if (!response.ok) {
        throw new Error('Failed to reset password.');
      }

      return await response.json();
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  }

  async validateResetToken(resetToken) {
    try {
      const response = await fetch(`${this.apiUrl}/validateResetToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ resetToken })
      });

      if (!response.ok) {
        throw new Error('Invalid reset token.');
      }

      return await response.json();
    } catch (error) {
      console.error('Error validating reset token:', error);
      throw error;
    }
  }

  async requestPasswordReset(email) {
    try {
      const response = await fetch(`${this.apiUrl}/requestPasswordReset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        throw new Error('Failed to request password reset.');
      }

      return await response.json();
    } catch (error) {
      console.error('Error requesting password reset:', error);
      throw error;
    }
  }

  async signIn(email, password) {
    try {
      const response = await fetch(`${this.apiUrl}/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (!response.ok) {
        throw new Error('Invalid email or password.');
      }

      return await response.json();
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  async createAccount(user) {
    const { firstName, lastName, email, password, phoneNumber } = user;
    try {
      const response = await fetch(`${this.apiUrl}/createaccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          phoneNumber
        })
      });

      if (!response.ok) {
        let responseJson = await response.json()
        throw new Error(responseJson.errorMessage);
      }

      return await response.json();
    } catch (error) {
      console.error('Error creating account:', error);
      throw error;
    }
  }
}

export default new UserService();
