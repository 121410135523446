import BaseService from './BaseService'
import axios from 'axios';

class ClickTrackingService extends BaseService {
  constructor() {
    super();
    this.apiUrl = `${this.baseUrl}/api/clicktracking`;
  }

  async trackClick(clickEvent) {
    try {
      await axios.post(this.apiUrl, clickEvent, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getToken()}`
        }
      });
    } catch (error) {
      console.error('Error tracking click:', error);
    }
  }

  async getUserClickEvents(userId) {
    try {
      const response = await axios.get(`${this.apiUrl}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user click events:', error);
      throw error;
    }
  }

  async getAllClickEvents() {
    try {
      const response = await axios.get(`${this.apiUrl}/all`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching all click events:', error);
      throw error;
    }
  }

  async getUniqueVisits(page = 1, pageSize = 20) {
    try {
      const response = await axios.get(`${this.apiUrl}/unique-visits`, {
        params: { page, pageSize },
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching unique visits:', error);
      throw error;
    }
  }

  async getVisitorClickEvents(visitorId, page = 1, pageSize = 20) {
    try {
      const response = await axios.get(`${this.apiUrl}/visitor/${visitorId}`, {
        params: { page, pageSize },
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching visitor click events:', error);
      throw error;
    }
}

  getToken() {
    return JSON.parse(localStorage.getItem('user'))?.jwtToken;
  }
}

export default new ClickTrackingService();
