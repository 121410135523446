class BaseService {
    constructor() {
        this.baseUrl = 'https://api.modelfusion.io'; // Default base URL

        // Check if we're in a browser environment
        if (typeof window !== 'undefined' && window.location) {
            const isLocalDebug = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') &&
                                 (window.location.port === '8080' || window.location.port === '3000');
            
            if (isLocalDebug) {
                this.baseUrl = 'http://localhost:5259';
            }
        } else {
            // We're in a Node.js environment (like Jest)
            // You can add any Node.js specific logic here if needed
        }
    }
}

export default BaseService;