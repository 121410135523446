<template>
  <div class="sign-up-page">
    <div class="header">
      <div class="logo">
        <h2 class="py-2"><img src="@/assets/ModelFusionIcon.png" alt="ModelFusion" /> Create Account</h2>
      </div>
      <div class="user-actions">
        <!-- <p class="signup-link">Already have an account? </p> -->
        <b-button variant="link" @click="goToLogin" class="mb-1">Login!</b-button>
      </div>
    </div>

    <div class="sign-up-form">
      <b-alert v-if="loginError" variant="danger" show dismissible @dismissed="loginError = false" class="custom-alert">
        <template #dismiss>
          <span class="dismiss">&times;</span>
        </template>
        {{ loginError }}
      </b-alert>
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col>
            <b-form-group label="First name" label-for="first-name">
              <b-form-input id="first-name" v-model="user.firstName" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Last name" label-for="last-name">
              <b-form-input id="last-name" v-model="user.lastName" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" type="email" v-model="user.email" required :state="emailState" @change="clearEmailState"></b-form-input>
        </b-form-group>

        <b-form-group label="Password" label-for="password">
          <b-form-input id="password" type="password" v-model="user.password" required :state="passwordState" @change="clearPasswordState"></b-form-input>
        </b-form-group>

        <b-form-group label="Confirm password" label-for="confirm-password">
          <b-form-input
            id="confirm-password"
            type="password"
            v-model="user.confirmPassword"
            required
            :state="passwordState"
            @change="clearPasswordState"
          ></b-form-input>
        </b-form-group>

        <p class="terms">
          By creating an account you agree to the <router-link to="/privacy">Privacy Policy</router-link> and
          <router-link to="/terms">Terms of Service</router-link>.
        </p>

        <b-button type="submit" variant="primary" block>Create Account</b-button>
      </b-form>

      <!-- Loading mask -->
      <div v-if="isLoading" class="loading-mask">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
  import { PLANS } from '@/constants/planConstants';

  export default {
    name: 'SignUpPage',
    props: {
      modal: Boolean,
    },
    data() {
      return {
        loginError: '',
        user: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
        },
        planName: '',
        isMonthly: true,
        emailState: null,
        passwordState: null,
        plans: PLANS,
        isLoading: false,
      };
    },
    created() {
      // Get plan information from query parameters
      this.planName = this.$route.query.plan || '';
      this.isMonthly = this.$route.query.isMonthly !== 'false';
    },
    methods: {
      async handleSubmit() {
        if (this.user.password !== this.user.confirmPassword) {
          this.loginError = 'Passwords do not match.';
          this.passwordState = false;
          return;
        }
        try {
          this.isLoading = true;
          let response = await this.$store.dispatch('createAccount', this.user);
          if (response) {
            // If account creation is successful, redirect to the appropriate payment link
            this.redirectToPayment();
          }
        } catch (error) {
          this.emailState = false;
          this.loginError = error;
        } finally {
          this.isLoading = false;
        }
      },
      clearEmailState() {
        this.emailState = null;
      },
      emitClose() {
        this.$emit('close');
      },
      goToLogin() {
        if (this.modal) {
          this.$emit('login');
        } else {
          this.$router.push({ path: '/login', query: this.$route.query });
        }
      },
      clearPasswordState() {
        this.passwordState = null;
      },
      redirectToPayment() {
        const plan = this.plans.find((p) => p.name === this.planName);
        if (plan) {
          const paymentLink = this.isMonthly ? plan.link : plan.weeklyLink;
          window.location.href = paymentLink;
        } else {
          // If no plan is specified, redirect to the pricing page
          if (this.modal) {
            this.emitClose();
          } else {
            this.$router.push({ path: '/chat', query: this.$route.query });
          }
        }
      },
    },
  };
</script>

<style scoped>
  .sign-up-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .logo img {
    width: 50px;
    height: 50px;
  }

  .user-actions {
    font-size: 14px;
  }

  .sign-up-form {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .terms {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .separator {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .separator span {
    margin: 0 10px;
  }

  .logo-icon {
    width: 20px;
    margin-right: 10px;
  }

  .testimonials {
    margin-top: 60px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .testimonial {
    margin-bottom: 40px;
  }

  .rating .icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .read-more {
    color: #007bff;
    cursor: pointer;
  }

  .twitter-user {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .twitter-icon {
    width: 20px;
    margin-right: 10px;
  }

  .verified-icon {
    margin-left: 5px;
  }

  .signup-link {
    margin-top: 20px;
    text-align: center;
  }
  .signup-link a:hover {
    text-decoration: underline;
  }

  .signup-link a {
    color: #007bff;
    text-decoration: none;
  }

  .loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
</style>
